<template>
  <div>
    <div class="flex mb-2">
      <label
        class="mr-2 text-xs font-light text-jooycar-gray-medium"
        :for="name"
      >
        {{ title | allCaps }}
      </label>
      <info-tooltip
        v-if="tooltipText || $slots.default"
        :text="tooltipText"
      >
        <slot />
      </info-tooltip>
    </div>
    <vue-slider
      :value="value"
      :contained="true"
      :data="optionsAsObject"
      tooltip="none"
      :adsorb="true"
      :dot-size="22"
      @change="selectOption"
      class="slider"
      :style="{ 'width': `${sliderWidth}` }"
    />
  </div>
</template>

<script>
import resolveConfig from 'tailwindcss/resolveConfig';
import InfoTooltip from './info-tooltip';
import tailwindConfig from '../../tailwind.config';

const VueSlider = () => import('vue-slider-component');

export default {
  components: {
    InfoTooltip,
    VueSlider,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    tooltipText: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    selectOption(value) {
      this.$emit('input', value);
    },
  },
  computed: {
    optionsAsObject() {
      return this.options.map((option) => {
        if (typeof option === 'object' && option !== null) {
          return option;
        }

        return { value: option, label: option.toUpperCase() };
      });
    },
    sliderWidth() {
      const fullConfig = resolveConfig(tailwindConfig);
      const viewPortWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const largeBreakpoint = parseInt(fullConfig.theme.screens.lg, 10);

      // eslint-disable-next-line no-magic-numbers
      return viewPortWidth > largeBreakpoint ? `${(this.options.length - 1) / 3 * 100}%` : '100%';
    },
  },
  filters: {
    allCaps(val) {
      return val.toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
  .slider ::v-deep {
    $themeColor: #d8d8d8;
    $bgColor: #d8d8d8;
    @import 'vue-slider-component/lib/theme/material.scss';
    @apply box-border;

    & .vue-slider-rail {
      @apply h-1;
      @apply cursor-pointer;
    }

    & .vue-slider-mark-step {
      @apply rounded-full;
      @apply h-3;
      @apply w-3;
      @apply cursor-pointer;
      top: -4px;
    }

    & .vue-slider-mark-label {
      @apply text-jooycar-blue-darkest;
      @apply font-bold;
      @apply leading-none;
      @apply text-xs;
      @apply text-center;
      @apply mt-3;
    }

    & .vue-slider-dot-handle {
      @apply border-2;
      @apply border-jooycar-blue;
      @apply bg-white;
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, .4);
    }
  }
</style>
