export default {
  deductible: `
    Es el monto de dinero que siempre tienes que pagar en caso de daños a tu auto, por otra parte, la compañía cubrirá \
    la diferencia entre el valor total del arreglo y el deducible que hayas contratado. Hay que tener en consideración \
    que este valor es inverso a la prima que pagas por tu seguro, es decir, mientras mayor es el monto del deducible \
    menor es la prima del seguro.\
  `,
  civilLimit: `
    Es el monto que contratas para cubrir posibles daños a otras personas o cosas. Existen tres tipos de \
    responsabilidad civil: por los daños emergentes, asociados a las cosas y a lesiones de alguna persona. \
    Por los daños morales, que se dan cuando el tercero sufre daños psicológicos. Y por el lucro \
    cesante, que se aplica en caso que esta persona afectada pierda su herramienta de \
    trabajo y no pueda generar ingresos. El limite aplica para cada cobertura de terceros antes mencionada.\
  `,
  carReplacement: `
    En caso de que sufras un accidente y mientras tu auto esté en reparación, puedes disponer de un vehículo de \
    reemplazo por la cantidad de días que escojas y con un valor de copago que está estipulado en tu póliza.\
  `,
  repairShop: `
  Puedes escoger entre 2 opciones “Taller de marca”, esta opción consiste en que, en caso de siniestro, tu auto \
  siempre será reparado en un taller de la red de concesionarios de la marca de tu auto con quienes tengamos \
  convenio, o “Multimarca”, en este caso, tú podrás elegir un taller de un listado que te entregará la Compañía \
  y que no necesariamente son de una marca específica.\
`,
  assistance: `
    Servicios adicionales que están incluidos en tu póliza. Los más importantes son los de grúa de rescate, mecánica \
    ligera (carga de batería, cambio de neumáticos, cerrajería, fallas eléctricas, etc.) Puedes ver más detalles estas \
    y otras asistencias aquí.\
  `,
  apov: `
    Cubre los gastos generados por un accidente, de todos los pasajeros que resulten heridos, incapacitados o que \
    fallezcan, de acuerdo a la capacidad de asientos del auto y hasta el monto contratado.\
  `,
  chargeDamage: `
    Cubre los daños causados a terceros de forma accidental y directamente por los objetos transportados en tu auto.\
  `,
  penalDefense: `
    Son los costos derivados de causas penales que podrías sufrir, debido a un siniestro en el que estés involucrado. \
    Cubre los honorarios, costas judiciales y fianzas hasta un máximo de UF 150.\
  `,
  foreignTripsDamages: `
  Son los daños a tu auto que ocurran fuera del territorio de la República de Chile, es decir, se mantienen las mismas \
  coberturas contratadas. Las coberturas de Robo y de Resposabilidad Civil no aplican por no ser un daño al vehículo.
  `,
  waterVaccuum: `
    Es una cobertura adicional que cubre los daños causados al motor por la aspiración de agua u otro líquido, \
    debido a algún accidente en la vía pública o a algún desastre de la naturaleza que provoque inundación.\
  `,
  theft: `
    Cobertura adicional que cubre las pérdidas a causa de robo con fuerza en las cosas y/o violencia en las \
    personas de las cosas en tu auto. Solo están cubiertos los objetos nombrados en las condiciones \
    particulares de la póliza.\
  `,
  chasis: `
    El número de Chasis o Número del VIN es un código de identificación único de 17 dígitos. \
    El lugar más común donde se puede encontrar el número de chasis es revisando desde la parte frontal \
    en el vidrio panorámico o parabrisas, del lado del conductor. Está en una placa adherida al tablero o cluster.\
  `,
  motorNumber: `
    Es el número de serie de fabricación del motor, en general lo encuentras grabado en el bloque del motor mismo o \
    también señalado en el padrón del vehículo.\
  `,
};
