/* eslint-disable no-undef */
module.exports = {
  future: {
    purgeLayersByDefault: true,
  },
  purge: {
    enabled: process.env.NODE_ENV === 'production',
    content: [
      './src/**/*.html',
      './src/**/*.vue',
      './src/**/*.js',
      './src/**/*.erb',
      './public/**/*.html',
    ],
  },
  theme: {
    extend: {
      spacing: {
        '26': '6.5rem',
        '80': '20rem',
        '120': '30rem',
      },
      fontSize: {
        '2xs': '0.5rem',
        '1s': '1rem',
      },
      opacity: {
        '80': '.8',
      },
      colors: {
        'jooycar-blue': {
          translucent: 'rgba(51, 102, 255, 0.08)',
          sky: '#009EE0',
          lighter: '#97C1E3',
          light: '#5086d2',
          quotation: '#0194d9',
          default: '#4066b3',
          dark: '#003399',
          darker: '#000066',
          darkest: '#222b45',
        },
        'jooycar-gray': {
          lightest: '#f7f9fd',
          lighter: '#c5cee0',
          light: '#8f9bb3',
          default: '#b8b8b8',
          medium: '#818181',
          dark: '#676666',
          stepper: '#a6a6a6',
          stepperlight: '#e5e4e5',
          stepperBg: '#f7f7f7',
          footer: '#e1dedb',
          translucent: '#f7f9fdc7',
        },
        'jooycar-purple': {
          lighter: '#5352a0',
          dark: '#0a0c74',
        },
      },
    },
  },
  variants: {
    borderWidth: ['responsive', 'last'],
  },
  plugins: [],
};
