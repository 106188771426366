// Code injection generated by Hotjar

export default function hotjar() {
  if (!(Object.keys(process.env).includes('VUE_APP_HOTJAR_ID')) || process.env.VUE_APP_HOTJAR_ID === '') return;

  window.hj = window.hj||function(){(
    window.hj.q = window.hj.q||[]
  ).push(arguments)};
  window._hjSettings = {
      hjid: process.env.VUE_APP_HOTJAR_ID,
      hjsv: process.env.VUE_APP_HOTJAR_SV,
  };

  const a = document.getElementsByTagName('head')[0];
  const r = document.createElement('script');

  r.async = 1;
  r.src='https://static.hotjar.com/c/hotjar-' + window._hjSettings.hjid + '.js?sv=' + window._hjSettings.hjsv;
  a.appendChild(r);
}
